/**
 * 时间格式转换器
 * @param {*} time 时间撮
 * @param {*} format 格式
 * @returns
 */
export function formatterDate(time, format) {
  if (time == null || time == "") {
    return "暂无日期";
  }
  switch (Number(format)) {
    case 1:
      format = "yyyy-MM-dd HH:mm:ss";
      break;
    case 2:
      format = "yyyy/MM/dd";
      break;
    case 3:
      format = "yyyy/MM/dd HH:mm:ss";
      break;
    case 4:
      format = "yyyy-MM";
      break;
    default:
      format = "yyyy-MM-dd";
  }
  let d = new Date(time);
  let o = {
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度
    "M+": d.getMonth() + 1, //月
    "d+": d.getDate(), //日
    "H+": d.getHours(), //时
    "m+": d.getMinutes(), //分
    "s+": d.getSeconds(), //秒
    S: d.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(format))
    format = format.replace(
      RegExp.$1,
      (d.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let i in o)
    if (new RegExp("(" + i + ")").test(format))
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[i] : ("00" + o[i]).substr(("" + o[i]).length)
      );
  return format;
}

//对象转选项
export function ObjToOpt(obj) {
  let options = [];
  for (let i in obj) {
    options = [
      ...options,
      {
        value: Number(i),
        label: obj[i],
      },
    ];
  }
  return options;
}
